@import '../../common.scss';

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none;
}

.img {
  position: absolute;
  top: toVw(12);
  left: toVw(12);
  width: toVw(30);
  height: toVw(30);
}